// .realtimeQa {
//   min-height: 850px;
// }
.banner-container {
  background-color: #e9f0ff;
}

.q-grid-Header {
  background-color: #e9f0ff;
  padding: 5px 0;
}

.refereshbutton {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background: none !important;
    border: none;
    padding: 0 !important;
    /*optional*/
    font-family: arial, sans-serif;
    /*input has OS specific font-family*/
    color: #069;
    text-decoration: none;
    cursor: pointer;
  }
}

.prevSessionLabel {
  float: left;
  padding-right: 15px;
}

.selectSessionOptions1 {
  width: 30% !important;
}

.sessionBtnContainer {
  button {
    width: 225px;
  }

  input {
    margin-right: 14px;
    border: 1px solid #ccc;
    background: white;
    padding: 12px;
  }

  .setStopBtns {
    display: flex;
    flex-direction: row;
    gap: 5px;
  }

}

.codeBox {
  background-color: #ffffff;
  border: 0px solid #d3dbef;
  border-radius: 20px;
  padding: 0px 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 6px;
}

.codeNum {
  font-size: 20px;
  font-weight: 500;
  color: #000;
  margin: 0;
}

.copyCodeBtn {
  background: #fdbc1700;
  color: #576374;
  padding: 7px;
  border-radius: 0px;
  border: none;
  cursor: pointer;
  font-weight: 500;
}

.sessionNameText {
  margin: 0;
  font-size: 20px;
  line-height: 22px;
  color: #000;
  font-weight: 500;
}

.themeRow {
  padding-top: 10px;
}

.queText {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: #000;
  margin-bottom: 10px;
  padding-left: 30px;
  cursor: pointer;
  padding-top: 20px;
}

.lightBlueSmallBtn {
  background: #d9e4fd;
  color: #4252AF;
  font-size: 14px;
  font-weight: 500;
  padding: 3px 12px;
  margin: 0 5px;
  border-radius: 15px;
  border: none;
  cursor: pointer;

  &:hover {
    color: #000;
  }
}

.lightBlueSmallBtn10 {
  background: #d9e4fd;
  color: #4252AF;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 500;
  padding: 3px 12px;
  margin: 0 5px 0 0;
  border-radius: 15px;
  border: none;
  cursor: pointer;

  &:hover {
    color: #000;
  }
}

.lightPinkSmallBtn10 {
  background: #f3bf4626;
  color: #ca9009;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 500;
  padding: 3px 12px;
  margin: 0 5px 0 0;
  border-radius: 15px;
  border: none;
  cursor: pointer;

  &:hover {
    color: #000;
  }
}

.trascriptBox {
  background-color: #ebf0f8;
  border: 1px solid #d3ddef;
  padding: 10px;
  border-radius: 8px;
  height: 110px;
  overflow: auto;

  span {
    font-size: 12px;
    font-weight: 500;
    color: #576374;
  }

  p {
    font-size: 14px;
    font-weight: 500;
    padding-top: 20px;
  }
}

.editTranscriptBtn {
  background: url(../../assets/images/edit-question-icon.svg) no-repeat center center;
  height: 24px;
  width: 24px;
  background-size: contain;
  padding: 0;
  opacity: 0.6;
}

.bg-white-with-shadow {
  background-color: #fff;
  border: 1px solid #e0e4e8;
  border-radius: 16px;
  -webkit-box-shadow: 0px 8px 24px 0px #959da533;
  -moz-box-shadow: 0px 8px 24px 0px #959da533;
  box-shadow: 0px 8px 24px 0px #959da533;
  padding: 10px 24px !important;
  min-height: 349px;
}

.transcriptBtns {
  display: flex;
  align-items: flex-end;
  gap: 40px;
  padding-top: 30px;
  justify-content: flex-start;

  button,
  ToggleButton {
    border: 0;
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;

    span {
      text-wrap: nowrap;
      text-align: center;
      font-size: 12px;
      color: #576374;
      text-transform: none;
      height: 18px;
    }
  }

  .MuiToggleButton-root {
    &:hover {
      background-color: transparent;
    }
  }
}

.deleteTranscriptBtn {
  &:disabled {
    opacity: 0.5;
  }

  img {
    height: 32px;
    width: 32px;
  }
}

.recordingBtn {
  img {
    height: 50px;
    width: 50px;
  }
}

@keyframes waveAnimation {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: -1000px 0;
  }

  100% {
    background-position: 0 0;
  }
}

.waveAni {
  background: url("../../assets/images/recording-waves.png") repeat-x;
  animation: waveAnimation 100s linear infinite;
  height: 32px;
  width: 32px;
}

.waveAniStop {
  background: url("../../assets/images/recording-waves.png") repeat-x;
  // animation: waveAnimation 100s linear infinite;
  height: 32px;
  width: 32px;
}

.wavetBtn {
  overflow: hidden;
  height: 50px;
  width: 32px;
  // cursor: pointer;
}

.qTabs {
  .nav-tabs {
    .nav-link {
      border: 0;
      margin-bottom: 0;
      color: #576374 !important;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;

      &.active {
        color: #4252AF !important;
        background-color: #f2f6ff !important;
        border-bottom: 2px solid #4252AF !important;
      }
    }
  }
}

.psAcc {
  .MuiAccordion-root {
    box-shadow: none !important;
    border-bottom: 1px solid #ccc;
    border-radius: 0 !important;
  }

  // .MuiAccordionSummary-root {
  // min-height: 60px;
  // }
  .MuiTypography-root {
    font-size: 12px !important;
    width: 100%;
  }

  .acc-content {
    // height: 410px;
    overflow-y: auto;
    overflow-x: hidden;
    font-size: 16px;
    font-weight: normal;
    padding-left: 44px;
  }

  .MuiAccordionSummary-expandIconWrapper {
    order: -1;
    background-color: #576374;
    border-radius: 100%;
    margin-right: 20px;
    color: rgba(255, 255, 255, 1);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);

    &.Mui-expanded {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(0deg);
    }
  }

  .MuiAccordionDetails-root {
    padding: 0px 0px 16px 16px;
  }

  .csvBtnContainer {
    width: 100%;
    width: 100%;
    position: absolute;
    top: 26px;
    right: 20px;
  }

  .exportStudent {
    float: left;
    margin-right: 14.5%;
    top: 25px !important;

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {
      width: 50px;
      display: flex !important;
      flex-grow: 1 !important;
      flex-direction: row !important;
    }
  }

  .viewQuestions {
    float: left;
    top: 25px !important;
  }

  .exportRepresentative {
    float: left;
    margin-right: 30%;
    top: 25px !important;

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {
      width: 50px;
      display: flex !important;
      flex-grow: 1 !important;
      flex-direction: row !important;
    }

  }

  .exportCSVbtn {
    background: white;
    color: #4252AF;
    font-size: 10px;
    padding: 0;
    text-transform: uppercase;
    align-items: center;
    display: inline-flex;
    justify-content: flex-end;
    position: absolute;
    right: 0;
    top: -2px;
    z-index: 2;

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {
      width: 50px;
      display: flex !important;
      flex-grow: 1 !important;
      flex-direction: row !important;
    }

    &:hover {
      color: #000;
      font-weight: 500;
    }
  }
}

.sortByBtn {
  background: #f2f6ff;
  border: 1px solid #d9e4fd;
  color: #4252AF;
  font-size: 14px;
  padding: 5px 20px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  border-radius: 20px;

  &:hover {
    background: #e5edff;
    color: #4252AF;
  }
}

.searchSession {
  position: relative;

  .MuiFormControl-root {
    width: 300px;

    .MuiInputBase-root {
      background: transparent;
      border: 0px solid #ccc;
      border-radius: 36px;
      padding: 0px 10px;
      height: 32px;
      // width: 300px;
    }

    input:-webkit-autofill {
      background-color: rgba(255, 255, 255, 0) !important;
    }
  }
}

.themeTitle {
  font-size: 24px;
  color: #4252AF;
  font-weight: 400;
}

@media screen and (max-width: 768px) {

  // .realtimeQa {
  //   min-height: unset;
  // }
  .bannerContainer {
    h2 {
      font-size: 22px;
    }
  }

  .transcriptBtns {
    justify-content: space-between;
  }

  .bannerHead {
    font-size: 22px;
  }

  .sessionNameText {
    font-size: 18px;
    margin: unset;
  }

  .banner-container {
    padding-bottom: 20px;
  }

  .psAcc {
    .csvBtnContainer {
      position: relative;
      top: 0;
      right: 0;
      padding-bottom: 20px;
    }
  }
}

@media screen and (max-width: 576px) {
  .qTabs {
    .nav-tabs {
      .nav-link {
        font-size: 11px;
      }
    }
  }

  .sessionBtnContainer {
    input {
      width: 100%;
      margin-bottom: 10px;
    }


    button {
      width: 100%;
      margin-bottom: 10px;
    }

  }

  .codeBox {
    width: 100%;
    margin: 0;
  }

  .searchSession {
    .MuiFormControl-root {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  .sessionNameText {
    font-size: 16px;
  }
}

.studentModal {

  .modal-body {
    padding-bottom: 30px;
  }

  .modal-content {
    width: fit-content;
    // 800px;
  }

}