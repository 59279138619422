.breadcrumbH5 {
  padding-left: 10px;
}

.buttonStudent {
  background: none !important;
  text-decoration: underline;
  color: black !important;
}

.modal-body {
  background-color: #fafcfe;
  max-height: 75vh;
  // min-height: 500px;
  overflow: auto;
}

.model-answers {
  padding-left: 40px;
}

.answer-container {
  position: relative;
  background-color: #fff;
  -webkit-box-shadow: 0px 8px 24px 0px #959da533;
  -moz-box-shadow: 0px 8px 24px 0px #959da533;
  box-shadow: 0px 8px 24px 0px #959da533;
  border-radius: 0px 16px 16px 0px;
  border-left: 0;
  padding: 5px 20px;
  margin-bottom: 30px;
}

.answer-correct-sign {
  display: inline-block;
  background-image: url("../../assets/images/answer-correct-sign.svg");
  background-size: contain;
  height: 24px;
  width: 24px;
  background-repeat: no-repeat;
  position: absolute;
  left: -40px;
  top: 2px;
}

.answer-incorrect-sign {
  display: inline-block;
  background-image: url("../../assets/images/answer-incorrect-sign.svg");
  background-size: contain;
  height: 24px;
  width: 24px;
  background-repeat: no-repeat;
  position: absolute;
  left: -40px;
  top: 2px;
}

.yrResTxt {
  color: #9fadbc;
  text-transform: uppercase;
}

.equationTxt {
  width: 100%;
  font-size: 26px;
  font-weight: 400;
  text-align: center;
  color: #424242;
  padding: 25px 0;
}

.equationTxtsmall {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #424242;

}

.equationTxtnonfrq {
  width: 600px;
  font-size: 26px;
  font-weight: 400;
  text-align: center;
  color: #424242;
  padding: 25px 0;
}

.equationTxtnonfrqsmall {
  width: 600px;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #424242;
  padding: 10px 0;
}

.pointsContainer {
  // display: flex;
  align-items: center;

  input {
    width: 60px;
    padding: 3px;
  }
}

.feedbackBox {
  padding-top: 20px;

  span {
    text-transform:capitalize;
  }

  textarea {
    background: #fff;
    width: 100%;
    border: 1px solid #ccc;
  }
}

.recommendedBox {
  border-radius: 16px;
  border: 1px solid #eaf0f7;
  background: #fafcfe;
  padding: 5px 16px;
  font-size: 16px;
  color: #424242;
  line-height: 21px;
  font-weight: 400;
  margin-bottom: 20px;
  width: 600px !important;
}

.recommendedBoxnonfrq {
  border-radius: 16px;
  border: 1px solid #eaf0f7;
  background: #fafcfe;
  padding: 14px 16px;
  font-size: 16px;
  color: #424242;
  line-height: 21px;
  font-weight: 400;
  width: 600px !important;
}

.errorTxt {
  border-radius: 10px;
  border: 1px solid #f3ccca;
  background: #fee9e8;
  padding: 14px 16px;
  font-size: 16px;
  color: #424242;
  line-height: 21px;
  font-weight: 400;
}

.correctTxt {
  border-radius: 10px;
  border: 1px solid #caf1c9;
  background: #ebfbeb;
  padding: 14px 16px;
  font-size: 16px;
  color: #424242;
  line-height: 21px;
  font-weight: 400;
}

.correctAnswerContainer {
  position: relative;
  border-left: 4px solid #39c136;
}

.correctAnswerContainerstudent {
  position: relative;
  border-left: 4px solid #39c136;
  margin-left: 30px;
}

.incorrectAnswerContainer {
  position: relative;
  border-left: 4px solid #ed706b;

  .equationTxt {
    color: #ed706b;
  }
}

.incorrectAnswerContainerstudent {
  position: relative;
  margin-left: 30px;
  border-left: 4px solid #ed706b;

  .equationTxt {
    color: #ed706b;
  }
}

.commonfeedbackclass {
  height: 300px;
  border-radius: 16px;
  padding: 16px;
  background: #fff;
  -webkit-box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.2);
  -moz-box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.2);
  box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.2);

}

.commonfeedbackclasswithoutHeight {
  border-radius: 16px;
  padding: 16px;
  background: #fff;
  -webkit-box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.2);
  -moz-box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.2);
  box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.2);

}

.solutionGraphCotainerStudent {
  height: 800px;
  border-radius: 16px;
  padding: 16px;
  background: #fff;
  -webkit-box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.2);
  -moz-box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.2);
  box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.2);

  .react-flow__controls {
    left: 0;
    bottom: 40px;
  }
}

.popupSectionTitle {
  color: #424242;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0 0 12px 0;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
}

.popupSectionTitlemediumborder {
  border-bottom: 1px solid #ccc;
}

.popupSectionTitlemedium {
  color: #424242;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  // padding: 0 0 12px 0;

  margin-bottom: 10px;
}

.popupSectionTitlemediumwithoutborder {
  color: #424242;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0 0 0px 0;
  // margin-bottom: 10px;
}

.eqpadding {
  padding: 10px 16px;
}

.popupSectionTitlemediumlatex {

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  // padding: 0 0 12px 0;  
  margin-bottom: 10px;
  max-width: 700px;
  overflow-x: auto;
}

.whiteBox {
  border-radius: 16px;
  padding: 16px;
  background: #fff;
  -webkit-box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.2);
  -moz-box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.2);
  box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.2);
}

@media only screen and (max-width: 600px) {
  .equationTxtnonfrqsmall {
    width: 200px
  }
}