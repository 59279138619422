// .realtimeQa {
//   min-height: 850px;
// }
.banner-container {
  background-color: #e9f0ff;
}

.q-grid-Header {
  background-color: #e9f0ff;
  padding: 5px 0;
}

.refereshbutton {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background: none !important;
    border: none;
    padding: 0 !important;
    /*optional*/
    font-family: arial, sans-serif;
    /*input has OS specific font-family*/
    color: #069;
    text-decoration: none;
    cursor: pointer;
  }
}

.prevSessionLabel {
  float: left;
  padding-right: 15px;
}

// .selectSessionOptions {
//   width: 30% !important;
// }

.sessionBtnContainer {
  button {
    width: 225px;
  }
}

.codeBox {
  background-color: #ffffff;
  border: 0px solid #d3dbef;
  border-radius: 20px;
  padding: 0px 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.codeNum {
  font-size: 20px;
  font-weight: 500;
  color: #000;
  margin: 0;
}

.copyCodeBtn {
  background: #fdbc1700;
  color: #576374;
  padding: 7px;
  border-radius: 0px;
  border: none;
  cursor: pointer;
  font-weight: 500;
}

.sessionNameTextH {
  margin: 0;
  font-size: 20px;
  line-height: 22px;
  color: #000;
  font-weight: 500;

  @media only screen and (max-width: 600px) {
    font-size: 12px !important;
  }
}



.sessionNameTextmedium {
  margin: 0;
  font-size: 16px;
  line-height: 22px;
  color: #000;
  font-weight: 500;
  text-align: end;

  @media only screen and (max-width: 600px) {
    font-size: 12px !important;
  }
}

.sessionNameTextsmall {
  margin: 0;
  font-size: 12px;
  line-height: 22px;
  color: #000;
  font-weight: 500;
  text-align: end;
  @media only screen and (max-width: 600px) {
    font-size: 10px !important;
  }
}

.queText {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: #000;
  margin-bottom: 10px;
}

.lightBlueSmallBtn {
  background: #d9e4fd;
  color: #4252AF;
  font-size: 14px;
  font-weight: 500;
  padding: 3px 12px;
  margin: 0 5px;
  border-radius: 15px;
  border: none;
  cursor: pointer;

  &:hover {
    color: #000;
  }
}

.lightBlueSmallBtn10 {
  background: #d9e4fd;
  color: #4252AF;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 500;
  padding: 3px 12px;
  margin: 0 5px 0 0;
  border-radius: 15px;
  border: none;
  cursor: pointer;

  &:hover {
    color: #000;
  }
}

.lightPinkSmallBtn10 {
  background: #f3bf4626;
  color: #ca9009;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 500;
  padding: 3px 12px;
  margin: 0 5px 0 0;
  border-radius: 15px;
  border: none;
  cursor: pointer;

  &:hover {
    color: #000;
  }
}

.trascriptBox {
  background-color: #ebf0f8;
  border: 1px solid #d3ddef;
  padding: 10px;
  border-radius: 8px;
  height: 110px;
  overflow: auto;

  span {
    font-size: 12px;
    font-weight: 500;
    color: #576374;
  }

  p {
    font-size: 14px;
    font-weight: 500;
    padding-top: 20px;
  }
}

.editTranscriptBtn {
  background: url(../../assets/images/edit-question-icon.svg) no-repeat center center;
  height: 24px;
  width: 24px;
  background-size: contain;
  padding: 0;
  opacity: 0.6;
}

.bg-white-with-shadow {
  background-color: #fff;
  border: 1px solid #e0e4e8;
  border-radius: 16px;
  -webkit-box-shadow: 0px 8px 24px 0px #959da533;
  -moz-box-shadow: 0px 8px 24px 0px #959da533;
  box-shadow: 0px 8px 24px 0px #959da533;
  padding: 10px 24px !important;
  min-height: 349px;
}

.bg-white-with-shadow-withoutheight {
  background-color: #fff;
  border: 1px solid #e0e4e8;
  border-radius: 16px;
  -webkit-box-shadow: 0px 8px 24px 0px #959da533;
  -moz-box-shadow: 0px 8px 24px 0px #959da533;
  box-shadow: 0px 8px 24px 0px #959da533;
  padding: 10px 24px !important;
  // min-height: 349px;
}

.transcriptBtns {
  display: flex;
  align-items: flex-end;
  gap: 40px;
  padding-top: 30px;
  justify-content: flex-start;

  button,
  ToggleButton {
    border: 0;
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;

    span {
      text-wrap: nowrap;
      text-align: center;
      font-size: 12px;
      color: #576374;
      text-transform: none;
      height: 18px;
    }
  }

  .MuiToggleButton-root {
    &:hover {
      background-color: transparent;
    }
  }
}

.deleteTranscriptBtn {
  &:disabled {
    opacity: 0.5;
  }

  img {
    height: 32px;
    width: 32px;
  }
}

.recordingBtn {
  img {
    height: 50px;
    width: 50px;
  }
}

@keyframes waveAnimation {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: -1000px 0;
  }

  100% {
    background-position: 0 0;
  }
}

.waveAni {
  background: url("../../assets/images/recording-waves.png") repeat-x;
  animation: waveAnimation 100s linear infinite;
  height: 32px;
  width: 32px;
}

.waveAniStop {
  background: url("../../assets/images/recording-waves.png") repeat-x;
  // animation: waveAnimation 100s linear infinite;
  height: 32px;
  width: 32px;
}

.wavetBtn {
  overflow: hidden;
  height: 50px;
  width: 32px;
  // cursor: pointer;
}

.qTabs {
  .nav-tabs {
    .nav-link {
      border: 0;
      margin-bottom: 0;
      color: #576374 !important;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;

      &.active {
        color: #4252AF !important;
        background-color: #f2f6ff !important;
        border-bottom: 2px solid #4252AF !important;
      }
    }
  }
}

.psAcc {
  .MuiAccordion-root {
    box-shadow: none !important;
    border-bottom: 1px solid #ccc;
    border-radius: 0 !important;
  }

  .MuiAccordionSummary-root {
    // min-height: 60px;
  }

  .MuiTypography-root {
    font-size: 12px !important;
    // width: auto;
  }

  .acc-content {
    // height: 410px;
    overflow-y: auto;
    overflow-x: hidden;
    font-size: 16px;
    font-weight: normal;
    padding-left: 44px;
  }

  .acc-content1 {
    // height: 410px;
    overflow-y: auto;
    overflow-x: hidden;
    font-size: 16px;
    font-weight: normal;
    margin-left: -44px;
  }

  .MuiAccordionSummary-expandIconWrapper {
    order: -1;
    background-color: #576374;
    border-radius: 100%;
    margin-right: 20px;
    color: rgba(255, 255, 255, 1);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);

    &.Mui-expanded {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(0deg);
    }
  }

  .MuiAccordionDetails-root {
    padding: 0px 0px 16px 16px;
  }
}

@media screen and (max-width: 768px) {

  // .realtimeQa {
  //   min-height: unset;
  // }
  .bannerContainer {
    h2 {
      font-size: 22px;
    }
  }

  .transcriptBtns {
    justify-content: space-between;
  }

  .bannerHead {
    font-size: 22px;
  }

  .sessionNameTextH {
    // font-size: 14px;
    margin: unset;
  }

  .banner-container {
    padding-bottom: 20px;
  }

  .psAcc {
    .csvBtnContainer {
      position: relative;
      top: 0;
      right: 0;
      padding-bottom: 20px;
    }
  }
}

@media screen and (max-width: 576px) {
  .qTabs {
    .nav-tabs {
      .nav-link {
        font-size: 11px;
      }
    }
  }

  .psAcc {
    .MuiAccordion-root {
      box-shadow: none !important;
      border-bottom: 1px solid #ccc;
      border-radius: 0 !important;
    }

    .MuiAccordionSummary-root {
      // min-height: 60px;
    }

    .MuiTypography-root {
      font-size: 12px !important;
      // width: auto;
    }

    .acc-content {
      // height: 410px;
      overflow-y: auto;
      overflow-x: hidden;
      font-size: 16px;
      font-weight: normal;
      padding-left: 44px;
    }

    .acc-content1 {
      // height: 410px;
      overflow-y: auto;
      overflow-x: hidden;
      font-size: 16px;
      font-weight: normal;
      margin-left: -44px;
    }

    .MuiAccordionSummary-expandIconWrapper {
      order: -1;
      background-color: #576374;
      border-radius: 100%;
      margin-right: 20px;
      color: rgba(255, 255, 255, 1);
      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg);

      &.Mui-expanded {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(0deg);
      }
    }

    .MuiAccordionDetails-root {
      padding: 0px 0px 6px 30px;
    }
  }

  @media screen and (max-width: 768px) {

    // .realtimeQa {
    //   min-height: unset;
    // }
    .bannerContainer {
      h2 {
        font-size: 22px;
      }
    }

    .transcriptBtns {
      justify-content: space-between;
    }

    .bannerHead {
      font-size: 22px;
    }

    .sessionNameTextH {
      // font-size: 18px;
      margin: unset;
    }

    .banner-container {
      padding-bottom: 20px;
    }

    .psAcc {
      .csvBtnContainer {
        position: relative;
        top: 0;
        right: 0;
        padding-bottom: 20px;
      }
    }
  }

  @media screen and (max-width: 576px) {
    .qTabs {
      .nav-tabs {
        .nav-link {
          font-size: 11px;
        }
      }
    }

    .sessionBtnContainer {
      button {
        width: 100%;
        margin-bottom: 10px;
      }
    }

    .codeBox {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .codeBox {
    width: 100%;
  }

  .searchSession {
    .MuiFormControl-root {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  .sessionNameTextH {
    font-size: 14px;
  }
}

.challengeTopich {
  //padding-top: 5px;   
  border-bottom: 1px solid rgb(209, 209, 209);

  h4 {
    //border-bottom: 1px solid rgb(209, 209, 209);
    padding-bottom: 15px;
  }

  .betaImage {
    //background-image: url(../../../assets/images/beta-1.png);
    position: fixed;
    width: 27px;
    height: 40px;
    right: 10px;
    //background-repeat: no-repeat;
    top: 10px;
  }

  .questions {
    //display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .borderbottom {
      border-bottom: 1px solid rgb(209, 209, 209);
    }

    .MuiCard-root {
      @media (min-width:992px) {
        //   width: calc(50% - 25px);
      }

      @media (min-width:1281px) {
        //  width: calc(33.33% - 54px);
      }

      @media (min-width:1800px) {
        // width: calc(25% - 37.5px);
      }
    }

    a {
      //transition: all 200ms linear;
      color: black;
      // max-width: 265px;
      // padding: 10px;
      // margin-bottom: 15px;
      // margin-right: 15px;
      text-decoration: none;
      width: 100%;

      // @media (min-width:992px) {
      //   width: calc(50% - 25px);
      // }

      // @media (min-width:1281px) {
      //   width: calc(33.33% - 34px);
      // }

      // @media (min-width:1800px) {
      //   width: calc(25% - 37.5px);
      // }
      .MuiCardMedia-img {
        object-fit: contain;
      }

      .challenge-text {
        float: left;
        width: calc(100% - 130px);

        h3 {
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 12px;
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          color: #212529;
          line-height: 26px;
        }

        p {
          line-height: 1.3em;
          text-overflow: ellipsis;
          overflow: hidden;
          height: 60px;
          margin-bottom: 0px;
          font-size: 16px;
          color: #212529;
          max-height: 100px;
        }


      }

      .alpha-data-label {
        font-size: 10px;
        font-weight: bold;
        color: #aeaeae;
        text-transform: uppercase;
        margin-right: 16px !important;
      }

    }

    // a:hover {
    //    box-shadow: rgba(0, 0, 0, 0.14) 0px 0px 2px 0px, rgba(0, 0, 0, 0.12) 0px 0px 0px 0px, rgba(0, 0, 0, 0.2) 0px 1px 3px 1px;
    //   text-decoration: none;
    // }
  }

  .threedots {
    box-shadow: "none";
    text-decoration: none;
    cursor: pointer;
  }

  .video-thumb {
    position: relative;
    margin-top: 5px;
    margin-bottom: 5px;
    float: left;
    margin-right: 10px;
    width: 120px;
    overflow: hidden;

    img {
      width: inherit;
      border-radius: 10px;
    }
  }

  .video_thumbnail {
    width: 120px;

    img {
      width: inherit;
    }
  }

  .threedots:after {
    content: "\2807";
    font-size: 20px;

  }

  .dropdown-menu {
    min-width: 0rem;
    padding: 0
  }

  .dropdown-item {
    padding: 5px !important;
    margin-bottom: 1px !important;
  }

  .cards,
  .fullcard {
    //transition: all 200ms linear;
    //color: black;    
    //max-width: 380px;    
    //height: 230px;
    //padding: 10px;
    margin-bottom: 30px;
    //border-radius: 10px;
    //margin-right: 10px;
    //float: left;
    //width: calc(100% - 130px);

    //box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

    &:hover {
      //box-shadow: rgba(0, 0, 0, 0.14) 0px 0px 2px 0px, rgba(0, 0, 0, 0.12) 0px 0px 0px 0px, rgba(0, 0, 0, 0.2) 0px 1px 3px 1px;
      text-decoration: none;
    }
  }

  .fullcard {
    .col {
      border: 1px solid #c1c1c1;
      border-top: 0;
      border-bottom: 0;
      border-left: 0;
    }

    .col:last-child {
      border: 0;
    }
  }

  .cards1 {
    transition: all 200ms linear;
    color: black;
    //width: 260px;
    height: 230px;
    padding: 10px;
    margin-bottom: 15px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.14) 0px 0px 2px 0px, rgba(0, 0, 0, 0.12) 0px 0px 0px 0px, rgba(0, 0, 0, 0.2) 0px 1px 3px 1px;
      text-decoration: none;
    }
  }

  .ch-title {
    font-size: 16px;
    font-weight: 600;
    display: -webkit-box;
    max-width: 200px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ch-titlep {
    font-size: 12px;
    font-weight: 600;
    display: -webkit-box;
    max-width: 200px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .headertext {
    font-size: 18px;
    font-weight: 600;
    display: flex;

    .wrap {
      width: 85%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .linkbutton {

      cursor: pointer;
      text-decoration: none solid rgb(33, 37, 41);
      font-size: 14px;
      color: #0080ff;
      line-height: 32px;
      text-align: right;

      &:hover {
        text-decoration: underline;
      }
    }


    .wrapborder {
      width: 98%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      border-bottom: 1px solid #ccc;

    }

    .hide {
      display: none;
    }
  }

  .challenge-statush {
    padding-top: 5px !important;

    .status-title {
      font-size: 12px;
      text-decoration: none;
      font-weight: 600;
      color: #28a745;
      // text-align: right;

      .left-align {
        text-align: left;
      }
    }

    .status-title-source {
      font-size: 12px;
      text-decoration: none;
      color: #28a745;

      .left-align {
        text-align: left;
      }
    }

    .user-title {
      font-size: 13px;
      text-decoration: none;
      font-weight: 600;
    }


  }

  .questionfonts {
    font-size: 13px !important;
    height: 150px;
    display: flex;
    flex-wrap: wrap;
    // margin-right: -15px;
    // margin-left: -15px;
  }


  .questionfontsmultih {
    font-size: 12px !important;
    display: -webkit-box;
    max-width: 400px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 40px;
  }

  .subheadertextQtype {
    // margin-left: 20px;
    color: #4252af;
    font-size: 16px;
    // border-bottom: 1px solid #ccc;
    padding-left: 5px;
    padding-bottom: 10px;
    margin-left: 0px;
    
  }
  .subheaderQuestions{
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #ccc;
    margin-left: 0px;
  }


  .textquestion {
    cursor: default;
    display: inline-block;
    position: relative;
    font-size: 12px;

    &:hover .iframe-preview {
      opacity: 1;
      transform: translateX(-50%) translateY(0) scale(1);
    }


  }

  .questionfonts {
    font-size: 13px !important;
    height: 150px;
    display: flex;
    flex-wrap: wrap;
    // margin-right: -15px;
    // margin-left: -15px;
  }

  .iframe-preview {
    transition: .2s ease-in-out opacity, .2s ease-in-out transform;
    opacity: 0;
    position: absolute;
    top: 10%;
    left: 50%;
    box-shadow: 0 1px 5px rgba(0, 0, 0, .5);
    width: 380px;
    padding-right: 30px;
    height: 400px;
    border: 1px solid #fff;
    overflow-x: hidden !important;
    overflow-y: auto !important;
    transform-origin: center bottom;
    transform: translateX(-50%) translateY(10px) scale(.9);
    background-color: #fff;
    z-index: 9999;

    iframe {
      transform: scale(.2);
      transform-origin: 0 0;
    }
  }

}

.card-title-link {
  font-size: 14px !important;
  cursor: pointer;

  a {
    color: #4252AF !important;
    line-height: 1 !important;
    cursor: pointer;
  }
}

.onrenderitem-example {

  .deni-react-treeview-item-container {

    .icon-and-text {
      cursor: default !important;
    }

    .treeview-item-example {
      display: flex;
      flex: 1;

      .treeview-item-example-text {
        flex: 1;
      }

      .actionButton {
        margin-right: 5px;
        cursor: pointer;
        opacity: 0.5;

        &.trash {
          color: #ff6666;
        }

        &.edit {
          color: #006699; //#00cc44;
          margin-top: 1px;
        }
      }
    }

    &:hover {
      .actionButton {
        visibility: visible;
        opacity: 1;
      }
    }

  }
}

.ch-cardsdata {
  width: 380px !important;
  height: 350px !important;
  border-radius: 10px !important;
  fill: #ffffff !important;
  border: none !important;
  box-shadow: 0px 1px 40px rgba(0, 0, 0, 0.05) !important;
  padding: 10px;
  margin-bottom: 35px;
  margin-right: 15px;
  margin-left: 15px;
}

.ch-cardsimage {
  width: 360px !important;
  height: 200px !important;
  border-radius: 10px !important;
  fill: #dadada !important;
}

.ch-cardsbody {
  padding: 0 !important;
}

.ch-cardstext {

  font-size: 16px !important;
  color: #212529 !important;
  text-decoration: none solid rgba(33, 37, 41, 0.9);
  line-height: 28px !important;
  margin-bottom: 0.60rem !important;
}

.ch-cardstitle {
  font-size: 18px !important;
  font-weight: bold !important;
  color: #212529 !important;
  text-decoration: none solid rgb(33, 37, 41);
  line-height: 1.2 !important;
  margin-bottom: 0.60rem !important;
  display: flex;
  justify-content: space-between;

}

.ch-cardsdatamulti {
  width: 240px !important;
  height: 330px !important;
  border-radius: 10px !important;
  fill: #ffffff !important;
  border: none !important;
  box-shadow: 0px 1px 40px rgba(0, 0, 0, 0.05) !important;
  padding: 10px;
  margin-bottom: 25px;
  // margin-right: 15px;
  // margin-left: 15px;
}

.ch-cardsimagemulti {
  width: 220px !important;
  height: 180px !important;
  border-radius: 10px !important;
  fill: #dadada !important;
}

.borderbotom {
  border-bottom: 1px dotted rgb(209, 209, 209);
}

.border-bottom-last:last-child {
  border-bottom: none;
}

.uppercards {
  // height: 95%;
  // margin: 10px;
  // display: flex;
  // overflow-x: unset;
  // overflow-y: unset;
  // border-radius: 10px;
  // flex-direction: column;
  // justify-content: flex-end;
  // background-color: #fff;
  // width: 100%;
}

@media (max-width: 1366px) {
  .ch-cardsdatamulti {
    margin-bottom: 25px;

  }
}

@media (max-width: 1024px) {

  // width: 380px !important;
  // height: 350px !important;
  // width: 360px !important;
  // height: 200px !important;
  .challengeTopic .headertext .wrap {
    width: 85%;

  }

  .ch-cardsdata {
    width: 300px !important;
    height: 370px !important;
    margin-bottom: 25px;
  }

  .ch-cardsimage {
    width: 280px !important;
    height: 220px !important;
  }

  .ch-cardsdatamulti {
    width: 200px !important;
    margin-bottom: 10px;
    margin-right: 15px;
    height: 280px !important;
  }

  .ch-cardsimagemulti {
    width: 180px !important;
    height: 120px !important;
  }
}

.statusContainerh {
  display: flex;
  align-items: center;
  font-size: 12px;
  //margin-top: 10px;

  span {
    //position: absolute;
    right: 10px;
  }
}