.withasteriskafter {}

.withasteriskafter::after {
    content: "*";

}

.withasteriskbefore {}

.withasteriskbefore::before {
    content: "*";

}

.radioMultipleIncorrectStep {
    label {

        span {
            font-size: 14px !important;
            font-weight: 600;
        }
    }

}

.defaultPercSummary {
    margin-top: -10px;
    margin-left: 20px;
    font-style: italic;

    span {
        font-size: 15px;
        font-weight: bold;
    }

    label {
        margin-bottom: 0px;
        font-size: 14px;
    }
}

.gradingGroupHeader {
    padding-bottom: 0px !important;
    cursor: pointer;
    padding: 10px;
    display: flex;
    text-decoration: underline;
    font-size: 16px;
    font-weight: 600;
}