.learnigImpactContainer {
    width: 100%;
    height: 90%;
    padding-top: 10px;

    .dropDownContainer{
        width: 100%;
        display: flex;
        gap: 20px;
        margin-bottom: 15px;
        align-items: flex-end;

        button{
            height: 50px;
        }
        // div{
        //     display: flex;
        // }
    }

    .barChartContainer{
        width: 100%;
        height: 50%;
        display: flex;

        div {
            width: 25%;
            height: 100%;
        }
    }

    .erroMsgContainer {
        display: flex;
        justify-content: center;
        font-size: 20px;
    }

}