.qaReportContainer {
    margin-bottom: 30px;

    .tableContainer {
        height: 100%;
        width: 100%;
        //    max-height: 500px;
        //    overflow-y: auto;
        overflow-x: auto;
    }

    .exportCSV {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        margin: 10px 10px 10px 0;
    }

    span {
        font-weight: bold;
        margin-right: 5px;
    }

    .tableHeader {
        display: flex;
        flex-direction: column;
        min-width: 100px;

        .subHeader {
            font-size: small;
            font-weight: 500;
        }
    }
}