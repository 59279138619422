.gradeFeedback {
    width: 100%;
    font-size: 13px;

    .linkButton {
        color: blue;
        cursor: pointer;
        text-decoration: underline;
        font-size: 14px;
    }

    .typetext {
        font-size: 15px;
    }
    .typetext-medium
    {
        font-size: 13px;
    }
}