.humanverification{


    .askAIQuehumanverification {
        background-color: #9fcaf9;
        color: #000;
        text-align: right;
        padding: 10px 15px;
        border-radius: 16px 0 16px 16px;
        width: 100%;
    
        div {
            float: right;
            margin-left: 5px;
        }
    
    }
    
    .askAIAnshumanverification {
        text-align: left;
        background-color: #ededed;
        color: #000;
        width: fit-content;
        word-break: break-all;
        padding: 10px 15px;
        border-radius: 16px 0 16px 16px;
    
        div {
            display: contents;
        }
    }

}