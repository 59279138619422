.curiosity-title {
  // margin-top: 16px;
  user-select: text;
  color: #303030;

  .pmstatementdiv {
    margin-left: 10px;
    margin-top: 10px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
    font-size: 18px;
    color: #303030;
    font-family: Roboto;
    font-weight: 600;
    line-height: 1.4;

  }

  .pmstatement {
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 7px;
    text-align: justify;
    line-height: 1.4;
    padding-left: 15px;
  }


  p {
    line-height: 1.5em;
  }

  h1,
  li,
  span,
  p {
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 7px;
    text-align: justify;
    line-height: 1.4;

    @media only screen and (max-width: 600px) {
      font-size: 12px !important;
    }

    //white-space: pre-wrap;
    li {
      margin-bottom: 10px;
      font-style: normal !important;

      em {
        font-size: 20px;

        @media only screen and (max-width: 600px) {
          font-size: 12px;
        }
      }
    }

    ol {
      list-style-type: none;
      counter-reset: elementcounter;
      margin-top: 0.5rem;
      padding-left: 60px;

      li {
        padding: 0 0 0 40px;
        font-style: normal !important;
      }

      li:before {
        content: "Part " counter(elementcounter, upper-alpha) ". ";
        counter-increment: elementcounter;
        margin-left: -60px;
      }
    }
  }

  .mjx-chtml {
    height: 0 !important;
  }
}

.alpha-data-label {
  font-size: 10px;
  font-weight: bold;
  color: #aeaeae;
  text-transform: uppercase;
  margin-right: 16px !important;
}

.question-image-answerboard {
  margin-top: 16px;
  max-width: 60%;
  mix-blend-mode: multiply;

}

.question-image-answerboardmobile {
  margin-top: 16px;
  max-width: 25%;
  mix-blend-mode: multiply;

}

.contentContainer {
  max-width: 80% !important;

  i {
    font-family: textttt !important;
  }
}

.divpadding0 {
  padding-top: 0;
}

.divpadding {
  padding-top: 15px;
}

.question-image-container {
  max-width: 25%;
}

.max-width {
  max-width: 100%;
}

.quiz-box {
  margin-top: 80px;
  padding-left: 70px;
  padding-right: 50px;

  .confirm-box {
    position: relative;

    .confirm-text {
      position: absolute;
      top: calc(50% - 15px);
      right: -20px;
    }
  }
}

.modal-content {
  border: none !important;

  img {
    max-width: 100%;
    // height: 50px
  }

  .red {
    color: red;
  }

  .green {
    color: green;
  }

  .eqfont {
    font-size: 1rem;
    font-weight: 500;


    @media only screen and (max-width: 600px) {
      font-size: 0.75rem;
    }
  }

  .modalscroll {
    max-width: 700px;
    overflow-x: auto;

    @media only screen and (max-width: 600px) {
      max-width: 300px;
    }
  }


  .eqfontText {
    font-size: 0.75rem;
    // font-weight: 500;
  }

  .icon-notification {
    color: #345ddf;
    font-size: 30px;
    font-weight: 300;
    margin: 30px 0 50px 0;

    @media only screen and (max-width: 600px) {
      font-size: 20px;
    }
  }
}

.modal-button {
  width: 100%;

  button {
    outline: none !important;

    &:hover {
      color: #fff;
    }
  }

  .width-btn {
    width: fit-content !important;
  }

  .yellow-btn {
    // height: 30px;
    line-height: 16px;
    font-size: 14px;
    width: 125px;
    // background-color: #fdbd17;
    background-color: #4252AF;
    border-radius: 0;
    color: #fff;
  }

  .blue-btnnew {
    // height: 30px;
    line-height: 16px;
    font-size: 14px;
    width: 170px;
    // background-color: #fdbd17;
    background-color: #4252AF;
    border-radius: 0;
    color: #fff;
  }

  .grey-btn {
    // height: 30px;
    line-height: 16px;
    font-size: 14px;
    width: 125px;
    background-color: #707070;
    border-radius: 0;
    color: #fff;
  }
}

.hintsModalTitle {
  font-size: 22px;
  font-weight: 300;
}

.ic {
  float: left;
  height: 17px;
  width: 17px;
  background-size: 100% 100%;
  margin-right: 5px;
  margin-top: 2px;

  &.ic-hint {
    background: url("../../assets/images/icon_hint.svg") no-repeat;
  }

  &.ic-check {
    background: url("../../assets/images/icon_check.svg") no-repeat;
  }

  &.ic-submit {
    background: url("../../assets/images/icon_submit.svg") no-repeat;
  }
}

.answer-modal-incorrect {
  width: 670px;

  @media only screen and (max-width: 600px) {
    width: 300px !important;
    word-wrap: break-word;
  }
}

.answer-model-correct {
  width: 400px;
}

.solutionContainer {
  width: 90%;
  height: 1500px;
}

.list-group-ranking {
  padding-left: 0;
  margin-bottom: 0;

  .list-group-item-ranking {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-bottom-width: 1px;
    cursor: move;

    &:first-child {
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
    }
  }

  .blue-background-class {
    background-color: #26baee;
    color: white;
  }
}

.MJXc-display,
.MJXp-display {
  text-align: left !important;
  width: max-content !important;
  margin: 0 !important;
  display: inline-block !important;
}

.modal-p {
  width: 500px;
}

@media only screen and (max-width: 600px) {
  .modal-p {
    width: 330px;
  }

  .pmstatement {
    font-size: 14px !important;
  }

  p {
    font-size: 12px !important;
  }

  li {
    font-size: 12px !important;
  }
}


.correct-answer {
  display: inline-block;
  background-image: url("/images/correct.svg");
  background-size: contain;
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
}

.incorrect-answer {
  display: inline-block;
  background-image: url("/images/wrong.svg");
  background-size: contain;
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
}

.marginclass {
  margin-left: 20px;
  margin-right: 20px;

}

.whiteboard-bottom-buttons {
  // position: fixed;
  // bottom: 10px;
  // z-index: 100;
  // border-radius: 8px;
  // width: 90%;
  // padding: 0.3rem;
  // right: 25px;
  // background: #ebf1ff;
  // margin-bottom: 50px;

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {
    bottom: 2px; 
    position: relative;
  }

  &.for-teacher {
    width: 650px;
    left: 25px;
  }

  div {
    padding: 0px;

    button {
      border-radius: 8px;
    }
  }
}

.has-answerboard-guide {
  position: relative !important;
}

.divflex {
  display: flex;
  justify-content: space-between;
}

.answerboard-guide {
  position: absolute;

  $GUIDE_COLOR: #ebf1ff;

  .arrow-down {
    width: 0;
    height: 0;
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;

    border-top: 14px solid $GUIDE_COLOR;
  }

  .arrow-left {
    width: 0;
    height: 0;
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent;

    border-right: 14px solid $GUIDE_COLOR;
  }

  .guide-text {
    background-color: $GUIDE_COLOR;
    border-radius: 6px;
    padding: 10px 15px;
    color: black;
    font-weight: 500;
  }

  &.check-work-button {
    width: max-content;
    top: -60px;
    right: 25px;

    .arrow-down {
      margin-left: auto;
      margin-right: 14px;
    }
  }

  &.wiris-button {
    width: max-content;
    top: -91px;
    left: -7px;

    .arrow-down {
      margin-right: auto;
      margin-left: 14px;
    }
  }
}

#ranking-selector {
  margin-top: 1rem;

  div:before {
    font-family: "Material Icons";
    content: "drag_indicator";
    color: #9d9da0;
    padding-right: 0.5rem;
    vertical-align: bottom;
  }

  div {
    margin: 0.25rem;
    background-color: #fcfcfc;
    border-radius: 0.25rem;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
  }

  .sortable-chosen {
    background-color: #f1f1f1;
    color: black;
    opacity: 1;
    border-left: 3px solid #fdbd17;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    &:before {
      color: #fdbd17;
      font-family: "Material Icons";
      content: "drag_indicator";
      padding-right: 0.5rem;
      vertical-align: bottom;
    }
  }
}

.text-bold {
  font-weight: bold;
}

.tuorporfile-content-card {
  width: 400px;

  .title {
    border-bottom: 1px solid rgb(209, 209, 209);
    // padding-bottom: 15px;
  }

  .closeIcon {
    cursor: pointer;
  }

  .headertitle {
    border-bottom: 1px solid #d1d1d1;
    display: flex;
    justify-content: space-between;
  }

  .content-card {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;


    transition: all 200ms linear;
    color: black;
    max-width: 380px;
    padding: 10px;
    margin-bottom: 8px;
    margin-right: 15px;

    .video-thumb {
      float: left;
      margin-right: 10px;
      width: 120px;
      overflow: hidden;

      img {
        width: inherit;
      }
    }

    .profile-card-text {
      float: left;
      width: calc(100% - 130px);

      h3 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 12px;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      p {
        line-height: 1.3em;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: .4rem;
      }

      .interactive-video-icon {
        position: relative;

        img {
          width: 30px;
          height: 30px;
        }
      }

    }

    a:hover {
      box-shadow: rgba(0, 0, 0, 0.14) 0px 0px 2px 0px, rgba(0, 0, 0, 0.12) 0px 0px 0px 0px,
        rgba(0, 0, 0, 0.2) 0px 1px 3px 1px;
      text-decoration: none;
    }
  }

  .alpha-data-label {
    font-size: 10px;
    font-weight: bold;
    color: #aeaeae;
    text-transform: uppercase;
    margin-right: 16px !important;
  }
}


.content-options-popup {
  display: flex;
  flex-direction: column;
  padding: 8px;
  position: absolute;
  background: white;
  z-index: 99999;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 0px 2px 0px, rgba(0, 0, 0, 0.12) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.2) 0px 1px 3px 0px;
  border-radius: 4px;

  .curriculum-content-card {
    .title {
      padding-bottom: 4px;
    }

    .content-card {
      a {
        border-radius: 4px;
        margin-bottom: 0px;
        padding: 4px;

        .content-card-text {
          h3 {
            margin-bottom: 0.3rem;
          }
        }
      }

      a:hover {
        box-shadow: rgba(0, 0, 0, 0.14) 0px 0px 2px 0px, rgba(0, 0, 0, 0.12) 0px 0px 0px 0px,
          rgba(0, 0, 0, 0.2) 0px 1px 3px 1px;
        text-decoration: none;
      }
    }
  }

  &.show-content-options-popup {
    display: flex;
  }

  &.hide-content-options-popup {
    display: none !important;
  }
}

.dialog {
  box-shadow: 0 8px 6px -6px black;
  position: static;
  left: 20%;
  top: 10%;
}

.solutionGraphCotainer {
  height: 700px;

  .scrollLinkContainer {
    display: flex;

    gap: 20px;

    p {
      color: #007bff;
      text-decoration: underline;
      cursor: pointer;
      font-weight: 300;
    }
  }

  .detailSolutionHeader {
    font-size: large;
    margin-left: 15px;
  }

  .header {
    font-weight: 600;
  }

  .infoLabel {
    margin-top: 10px;
    font-size: medium;
    font-weight: 600;
    font-style: italic;
  }
  .stepPointsContainer {
    display: flex;
    justify-content: space-between;

    .stepInfoContainer {
      display: flex;
      gap: 10px;

      .stepCount {
        font-weight: 600;
      }

      .stepDerivedInfo {
        // text-transform: capitalize;
      }
    }
  }

  .react-flow__controls {
    left: 0;
    top: 0;
  }
}

.feedbackmodal p {
  word-break: break-word;
  white-space: normal;
}

.graphdiv {

  width: 100%;
  height: 100%;
  cursor: move;

  .chartContainer {
    position: relative !important;
    max-height: 400px;
    height: 300px;
  }
}

.divFacts {
  width: 100%;
  height: auto;
  padding-top: 10px;
}

.redIcon {
  fill: red;
  height: 18px;
  width: 18px;
}

.greenIcon {
  fill: green;
  height: 18px;
  width: 18px;
}

.grayIcon {
  fill: gray;
  height: 18px;
  width: 18px;
}

.gray {
  color: gray;
}

.div60 {
  width: 60%;
}

.div40 {
  width: 40%;
}

.div50 {
  width: 50%;
}

.div0 {
  width: 0%;
}

.div100 {
  width: 100%;
}

.showSolutionTitle {
  font-weight: bold;
  border-bottom: gray solid 1pt;

  a {
    cursor: pointer;

    i {
      font: normal normal normal 14px/1 FontAwesome;
    }
  }
}

.checkMyAnswerTitle {
  font: bold;
  border-bottom: gray solid 1pt;
}

.divLineBreaker {
  margin-bottom: 0.5rem !important;
  border: gray solid 1px;
  margin-top: 0.5rem !important;
}

.legendContainer {
  display: flex;

  .legendItemContainer {
    display: flex;
    margin-right: 10px;
    margin-bottom: 10px;

    div {
      margin-top: 2px;
      margin-right: 5px;
      width: 15px;
      height: 15px;
    }

    .correct {
      background-color: #bfd4ff;
    }

    .incorrect {
      background-color: #ff0000;
    }

    .optional {
      background-color: #cccccc;
    }

    span {
      // position: absolute;
      // left: 25px;
      // top: 140px;
      font-size: 12px;
    }
  }
}

.solutionBox {
  // height: calc(100vh - 280px);
  height: calc(100vh - 420px);
  overflow: auto;
  -webkit-user-select: text;
  /* Safari */
  -ms-user-select: text;
  /* IE 10 and IE 11 */
  user-select: text;
}

.breadcrumbH5 {
  font-size: 14px;
  padding-top: 8px;
  text-decoration: none solid #212529;
  color: #007bff;
  line-height: 32px;
  padding-left: 0;
}

.questiondescription {
  padding-left: 10px;
  font-size: 18px;
  color: rgb(48, 48, 48);
  font-family: Roboto;
  font-weight: 600;
  line-height: 1.4;
}

.breadcrumb {
  // margin-left: -13px !important;
  font-size: 18px !important;
  margin-left: 10px;
  // background-color: #fff !important;
}

.extended-breadcrumb .link {
  // font-size: 18px !important;
}

.modal-body {
  background-color: #fafcfe;
  max-height: 75vh;
  // min-height: 500px;
  overflow: auto;
}

.Toastify__close-button {
  margin-left: 2px !important;
}

.askTutorImageContainer {
  width: 300px;
  margin-bottom: 10px;
}

.upgrateSubscriptionBox {
  color: #0000ff;
  padding-top: 5px;
  cursor: pointer;
}

.curiosity-titleAnyProb span {
  font-size: 16px;
}

.zoomImg {
  top: 10px;
  left: 55%;
  background-color: #fff;
}

.anyProblemChartBot {
  height: 100%;
  margin-bottom: 60px;
}

.feebackcompanyLogo {
  width: 30px;
}

.feedbackDialog {
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    max-width: 650px;
  }
}

.eqpaddingshowsolution {
  padding: 1px 16px;
}

.evalPref {
  color: black !important;
}

.radioPopup {
  height: 35px !important;
  margin-top: -5px;
}

.rightPopupMultipart {
  .MuiDialog-paper {
    max-height: 100%;
    left: 0;
    top: 0;
    margin: 0;
    border-radius: 0;
    position: absolute;
    overflow-y: auto;
    height: 95vh;
    z-index: 1000;
  }

  .MuiDialogTitle-root {
    padding-top: 0px !important;
  }

  .MuiIconButton-root {
    padding: 5px !important;
    right: 4px;
    top: 4px;
  }
}


.textPrbBtns {
  //  height: max-content;
  margin-right: 5px;

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {
    height: 90px !important;
  }
}

.issuecaptureimage{
  height: 400px;
  overflow-y: scroll;
  
}

.submitWorkBtnIssues {
  z-index: 10;
  background-color: #4252AF;
  border-radius: 5px !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  cursor: pointer;
  overflow: hidden;   
  flex-direction: column;   
  padding: 10px;
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {}
}

.submitissuestickybutton {
  position: absolute;
  bottom: 4%;
  left: 1%;

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {
    left: 75%;
    top: 83%;
  }
}

.advanceStepNote {
  font-size: 14px;
  font-style: italic;
  margin-bottom: 5px;
  width: fit-content;
}

.noteHighlighter{
  background-color: rgb(245, 245, 136);
}

