.questionRenderer {
    position: relative;
    color: #fff;
}

.questionText {
    color: #1b262f;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    letter-spacing: 0.15px;
    margin: 0px 24px 15px 24px;

    .p {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        width: 99%;
    }
}

.testTitleDiv {
    padding-right: 32px;
    // padding-bottom: 32px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    // gap: 16px;
    display: inline-flex;
}

.testTitleTextStudent {
    color: black;
    font-size: 22px;
    font-family: Open Sans;
    //  font-weight: 600;
    word-wrap: break-word;
    margin-bottom: 0px;
    padding-left: 15px;
}

.testTitleText {
    color: black;
    font-size: 22x;
    font-family: Open Sans;
    //  font-weight: 600;
    line-height: 48px;
    word-wrap: break-word;
    margin-bottom: 10px;
    padding-left: 15px;

    span {
        font-weight: 600;
        font-style: italic;
    }
}

.testTitledate {
    color: black;
    font-size: 18px;
    font-family: Open Sans;
    font-weight: 600;
    line-height: 48px;
    word-wrap: break-word;
    margin-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
}

.lineBreak {
    width: 100%;
    height: 0px;
    border: 4px rgba(120, 150, 178, 0.12) solid;
}

.questionTypeMainDiv {
    margin-top: 15px;
    position: relative;
    // left: 50px;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: inline-flex;
    background: rgba(237, 241, 245, 0.40);
    margin-bottom: 80px;
}

.questionTypeMainDivFRQ {
    margin-top: 15px;
    position: relative;
    // left: 50px;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: inline-flex;
    background: rgba(237, 241, 245, 0.40);
    margin-bottom: 20px;
}

.questionTypeDiv {
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 15px;
    padding-bottom: 10px;
    flex-direction: row;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 56px;
    display: inline-flex;
    width: 100%;
}

.questionTypeTitleDiv {
    align-self: stretch;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    display: inline-flex;
    float: left;
    width: 99%;
}

.questionTypeTitleText {
    color: #1B262F;
    font-size: 22px;
    font-family: 'Open Sans';
    font-weight: 600;
    // line-height: 38.40px;
    word-wrap: break-word
}

.frqInfo {
    padding-left: 35px;
    color: #000;
    font-style: italic;
}

.pointerclassblink {
    cursor: pointer;
    animation: blinker 1s linear infinite;
}

.avatar {
    width: 64px;
    height: 32px;
    background: #B9C9D7;
    border-radius: 4px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    padding-top: 6px;
}

.op {
    text-align: center;
    color: white;
    font-size: 20px;
    font-family: 'Roboto';
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
    word-wrap: break-word;
}

.minWidth {
    width: 40px;
    height: 40px;
    position: relative;
    transform: rotate(-90deg);
    transform-origin: 0 0;
}

.totalPoints25 {
    position: relative;
    position: relative;
    color: #1B262F;
    font-size: 16px;
    font-family: 'Average Sans';
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.15px;
    word-wrap: break-word;
    top: 5px;
    white-space: nowrap;
    //  left: 55%;
    margin-right: 30px;
    float: right;
}

.questionDivMain {
    position: relative;
    top: 7px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 40px;
    display: flex;
    margin: 30px;
    width: 98.5%;

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {
        margin: 17px;
        width: 99%;
    }
}

.questionDiv {
    position: relative;
    background: white;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    overflow: hidden;
    display: inline-flex;
    display: inline-flex;
    width: 96%;
}

.lineBreak_question {
    width: 100%;
    left: 24px;
    top: 70px;
    position: absolute;
    border: 4px rgba(120, 150, 178, 0.12) solid;
}

.questionDetails {
    width: 100%;
    position: relative;
    position: relative;
    top: 100px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    display: inline-flex;
    margin-bottom: 130px;
    color: #000000;
}

.questionHeaderText {
    left: 0px;
    top: 0px;
    position: relative;
    position: relative;
    color: #1B262F;
    font-size: 16px;
    font-family: 'Open Sans';
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.15px;
    word-wrap: break-word;
    float: left;
    margin-right: 10px;

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {
        font-size: 14px !important;
    }
}


.questionHeaderChapterText {
    left: 24px;
    top: 0px;
    position: relative;
    position: relative;
    color: #1B262F;
    font-size: 20px;
    font-family: 'Open Sans';
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.15px;
    word-wrap: break-word;
    float: left;
    margin-right: 20px;
    width: 99%;
}

.questionHeaderTextAttempted {
    left: 0px;
    top: 0px;
    position: relative;
    position: relative;
    color: #1B262F;
    font-size: 20px;
    font-family: 'Open Sans';
    line-height: 32px;
    letter-spacing: 0.15px;
    word-wrap: break-word;
    float: left;
    margin-right: 20px;
    color: green;
    font-weight: bold;
}

.questionRightDiv {
    float: right;
}

.gradeCalTrial {
    top: 4px;
    position: relative;
    text-align: right;
    color: #7896B2;
    font-size: 16px;
    font-family: 'Average Sans';
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.15px;
    word-wrap: break-word;
    float: left;
    margin-right: 20px;
}

.points5 {
    top: 4px;
    position: relative;
    text-align: right;
    color: #7896B2;
    font-size: 16px;
    font-family: 'Average Sans';
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.15px;
    word-wrap: break-word;
    float: left;
    margin-right: 20px;
}

.questionAvatar {
    width: 64px;
    height: 32px;
    top: px;
    position: relative;
    background: #B9C9D7;
    border-radius: 4px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    padding-top: 5px;
    margin-right: 30px;
}

.questionHeader {
    left: 24px;
    top: 20px;
    height: 32px;
    height: 32px;
    position: absolute;
    width: 99%;
    width: 99%;
}

.makeStyles-radioGrp-9 label,
.makeStyles-radioGrp-8 label {
    color: #1B262F;
    margin-bottom: 2px !important;
    line-height: 27px;
    position: relative;
    font-size: 18px;
}

.frqLinkDiv {
    width: 95%;
    display: flex;
    color: #7896B2;
    font-size: 15px;
    font-family: 'Roboto';
    text-transform: uppercase;
    line-height: 26px;
    letter-spacing: 0.46px;
    word-wrap: break-word;
    margin-left: 24px;
    margin-right: 24px;
    height: 80px;
    background-color: #F5F7F9;
    // margin-bottom: 50px;
    align-items: center;

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {
        margin-left: 20px;
        margin-right: 20px;
    }
}

.frqLink {
    width: 80%;
    align-items: center;
    text-align: center;
    align-self: center;

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {
        width: 68%;
    }

    a {
        color: #7896B2;
        font-size: 18px;
        font-family: 'Roboto';
        text-transform: uppercase;
        line-height: 26px;
        letter-spacing: 0.46px;
        word-wrap: break-word;

        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {
            font-size: 15px;
        }
    }
}

.disabled {
    pointer-events: none;
    cursor: default;
}

.examPaperFooterQuestion {
    width: 100%;
    //  padding-top: 10px;
    padding-bottom: 30px;
    padding-left: 250px;
    padding-right: 144px;
    background: #DFE2F3;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    //   margin-bottom: 30px;
    //   margin-top: 100px;
    position: fixed;
    z-index: 10;
    bottom: 40px;
    left: 0px;
    right: 0px;

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {
        //     padding-bottom: 20px;
        padding-left: 0px;
        padding-right: 0px;

        margin-bottom: 0px;
        margin-top: 0px;
    }
}


.scrollToTopDiv {
    width: 100%;
    //  padding-top: 10px;
    padding-bottom: 45px;
    padding-left: 250px;
    padding-right: 144px;
    //  background: #DFE2F3;
    justify-content: flex-end;
    align-items: flex-end;
    display: inline-flex;
    //   margin-bottom: 30px;
    // margin-top: 100px;
    position: fixed;
    z-index: 10;
    bottom: 45px;
    left: 0px;
    right: 0px;

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {
        //     padding-bottom: 20px;
        padding-left: 0px;
        padding-right: 0px;

        margin-bottom: 15px;
        margin-top: 0px;
    }
}

.progressFooter {
    position: relative;
    //   flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    width: 99%;
}

.countDownDiv {
    position: relative;
    top: 10px;
    left: 2%;

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {
        left: 5%;
    }
}

.divTimeCounter {
    left: 10%;
    top: 0;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: inline-flex;

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {
        left: 25% !important;
    }
}

.timeLeft {
    color: #51718E;
    font-size: 12px;
    font-family: 'Average Sans';
    font-weight: 400;
    text-transform: uppercase;
    line-height: 24px;
    letter-spacing: 1;
    word-wrap: break-word;
}

.timeNumber {
    color: #1B262F;
    font-size: 22px;
    font-family: 'Open Sans';
    font-weight: 400;
    line-height: 24px;
    word-wrap: break-word;
    align-self: center;
}

.btnSubmitDiv {
    float: left;
    margin-right: 25px;
}

.submitExamDiv {
    left: 45%;
    position: relative;
    float: right;
    margin-top: 25px;


    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {
        left: 30% !important;
    }
}

.questionsAttempted {
    margin-top: 5px;
    color: black;
    font-size: 22px;
    font-family: 'Average Sans';
    font-weight: 400;
    letter-spacing: 0.15px;
    word-wrap: break-word;
    margin-right: 15px;
    float: left;
}

.divGuideBtns {
    margin-bottom: 10px;
    align-self: flex-end;

    .toolLabel {
        font-size: 12px;
    }

    .toolLabelGrey {
        font-size: 12px;
        color: grey;
        margin-bottom: 0px;
    }

    .boardBtns {
        margin-top: 40px;
    }
}

.divMsgCorrectBtns {
    margin-left: 20px;
    position: relative;
    float: left;
    display: flex;
    margin-bottom: 10px;
    align-items: end;
    margin-right: 20px;
    margin-top: 30px;
    color: green;

    .toolLabel {
        font-size: 12px;
    }
}

.divMsgInCorrectBtns {
    margin-left: 20px;
    position: relative;
    float: left;
    display: flex;
    color: purple;
    margin-bottom: 10px;
    align-items: end;
    margin-right: 20px;
    margin-top: 30px;

    .toolLabel {
        font-size: 12px;
    }
}

.divOverallResult {
    width: 51%;
    margin-right: 15px;
    float: left;
    position: relative;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.20);
    border-radius: 8px;
    overflow: hidden;
    height: 100%;
    padding-bottom: 15px;

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {
        width: 99% !important;
    }
}

.divResultheading {
    width: 100%;
    margin-right: 15px;
    float: left;
    position: relative;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.20);
    border-radius: 8px;
    overflow: hidden;
    height: 100%;
}

.divProfiencyResult {
    width: 47%;
    float: left;
    position: relative;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.20);
    border-radius: 8px;
    overflow: hidden;
    height: 100%;

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {
        width: 99% !important;
    }
}


.divColor {
    width: 564px;
    height: 444px;
    left: 0px;
    top: 0px;
    position: relative;
    background: white;
}

.divHeaderass {
    //   left: 16px;
    top: 16px;
    position: relative;
    color: #1B262F;
    font-size: 24px;
    font-family: 'Open Sans';
    font-weight: 600;
    // line-height: 32.50px;
    // letter-spacing: 0.25px;
    word-wrap: break-word;
    // margin-bottom: 50px;
}

.marginbottom50 {
    margin-bottom: 50px;
}

.divProgress {
    height: 430px;
    left: 16px;
    top: 155px;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    display: inline-flex;

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {
        top: 100px !important;
        height: 365px !important;
    }

}

.divProgressGraph {
    height: 410px;
    width: 100%;

    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    display: inline-flex;
    margin-bottom: 20px;
    margin-top: 25px;

    .expandViewBtn {
        position: absolute;
        top: 0;
        right: 17px;
        z-index: 1;
    }
}

.divPointsCircle {
    justify-content: flex-start;
    align-items: center;
    gap: 32px;
    display: inline-flex;
}

.divPoints {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    display: inline-flex;
    position: relative;
    z-index: 10;
    left: 40px;
}

.divScore {
    text-align: center;
    color: #1B262F;
    font-size: 20px;
    font-family: 'Open Sans';
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.15px;
    word-wrap: break-word;
}

.divPointText {
    text-align: center;
    color: #1B262F;
    font-size: 12px;
    font-family: 'Average Sans';
    font-weight: 400;
    text-transform: uppercase;
    line-height: 31.92px;
    letter-spacing: 1px;
    word-wrap: break-word;
}

.divPointDesc {
    width: 242px;
    color: #364B5F;
    font-size: 18px;
    font-family: 'Average Sans';
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0.15px;
    word-wrap: break-word;
}

.divProficiencyProgress {
    width: 470px;
    height: 51px;
    position: relative;
}

.divTitle {
    left: 0px;
    top: 0px;
    position: relative;
    color: #51718E;
    font-size: 12px;
    font-family: 'Average Sans';
    font-weight: 400;
    text-transform: uppercase;
    line-height: 31.92px;
    letter-spacing: 1px;
    word-wrap: break-word;
}

.divColorBarBar {
    width: 415px;
    height: 12px;
    left: 0px;
    top: 5px;
    position: relative;
    justify-content: center;
    align-items: center;
    display: inline-flex;
}

.divLightGreenColorBar {
    width: 415px;
    height: 12px;
    background: #E8F5D9;
    border-radius: 71.50px;
}

.divLightBlueColorBar {
    width: 415px;
    height: 12px;
    background: #E5F3FA;
    border-radius: 71.50px;
}

.divDarkGreenBar {
    width: 360px;
    height: 11.92px;
    left: -0.19px;
    top: -19px;
    position: relative;
    justify-content: center;
    align-items: center;
    display: inline-flex;
}

.divDarkBlueBar {
    width: 400px;
    height: 11.92px;
    left: -0.19px;
    top: -19px;
    position: relative;
    justify-content: center;
    align-items: center;
    display: inline-flex;
}

.divYourProgresBars {
    width: 450px;
    height: 11.92px;
    background: #76B72E;
    border-radius: 71.50px;
}

.divPeerProgressBar {
    width: 450px;
    height: 11.92px;
    background: #2B9CD9;
    border-radius: 71.50px;
}

.divYourDigits {
    left: -60px;
    top: -80px;
    position: relative;
    text-align: right;
    color: #76B72E;
    font-size: 12px;
    font-family: 'Average Sans';
    font-weight: 400;
    text-transform: uppercase;
    line-height: 31.92px;
    letter-spacing: 1px;
    word-wrap: break-word;
}

.divPeerDigits {
    left: -60px;
    top: -78px;
    position: relative;
    text-align: right;
    color: #2B9CD9;
    font-size: 12px;
    font-family: 'Average Sans';
    font-weight: 400;
    text-transform: uppercase;
    line-height: 31.92px;
    letter-spacing: 1px;
    word-wrap: break-word;
}

.divBackLink {
    left: -1%;
    position: relative;
    top: 20px;
    display: flex;
    flex-direction: row;
}

.backlinkbutton {
    font-size: 16px;
}

.anyProblemChartBotpt {
    height: 100%;
}

.submitConfirmationButtonContainer {
    width: 100%;
    display: flex;
    justify-content: center;

    button {
        margin-right: 10px;
    }
}

.divIndividualSubmitBtn {
    display: flex;
    align-self: flex-start;
    margin-bottom: 10px;
    margin-left: 20px;
    margin-top: 48px;
    position: absolute;

    .toolLabel {
        font-size: 12px;
    }

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {}
}

.divGuideBtnsSubmit {
    display: flex;
    align-self: flex-start;
}

.recommendedTitle {
    color: #000;
    margin-left: 15px;
    font-size: 18px;
    padding-top: 10px;
    border-bottom: 1px solid #cecece;
}

.recommendedLink {
    color: #007bff;
    font-style: italic;
    text-decoration: underline;
    cursor: pointer;
    font-size: 20px;
    width: 95%;
    display: block;
}


.scrollToTop {
    display: flex;
    align-self: flex-end;
    flex-direction: column;
    top: -55px;
    position: relative;
}

.testTitleDiv table {
    display: flex;
    justify-content: flex-start;
    border: lightgray 1px solid;
    //  width: 100%;
    font-size: 14px;
}

.testTitleDiv table tr {
    width: auto;
}

.testTitleDiv table td {
    display: flex;
    width: auto;
    padding: 3px;
}

.queTd {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    border-right: lightgray 1px solid;
    float: left;
    margin-right: 1px;
    width: auto;
    padding: 5px;
}

.queTdBtn {
    padding-top: 8px !important;
    display: flex;
    align-items: center;
    margin: -6px 5px 0px 8px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    font-size: 14px;
    color: blue;
    cursor: pointer;
    text-decoration: underline;
}

.queTdQuestion {
    border-right: lightgray 1px solid;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin: 0px 0px 0px 12px;
    justify-content: center;
    padding: 5px;
}

.scoreTbl {
    border: lightgray 1px solid;
    width: 100%;
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    overflow-x: auto;
}

.dueDateExceeds {
    background: lightgrey;
}

.ForDiagramOnly {
    margin-top: 25px;
    display: flex;
    justify-content: flex-end;
    font-size: 11px;
    float: right !important;
    margin-right: 30px;
}

.queName {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
}

.askAISession {
    background-color: #ECF1FE;
    color: #000;
    text-align: left;
    width: 100%;
    padding: 10px 15px;
    border-radius: 16px 0 16px 16px;
    width: 100%;
    margin-top: 25px;
    border: 1px solid lightgray;
    padding-bottom: 2px;
}

.askAITutChat {
    width: 100%;
    display: grid;
}

.divChat {
    width: 100%;
    display: grid;
}

.askAIQue {
    background-color: #d8e0f3;
    color: #000;
    text-align: right;
    padding: 10px 15px;
    border-radius: 16px 0 16px 16px;
    width: 100%;

    div {
        float: right;
        margin-left: 5px;
    }

}

.askAIAns {
    text-align: left;
    background-color: #ededed;
    color: #000;
    width: fit-content;
    word-break: break-all;
    padding: 10px 15px;
    border-radius: 16px 0 16px 16px;

    div {
        display: contents;
    }
}

.aiTutorChatContainer {
    width: 94%;
}

ul.scoresummary {
    list-style-type: disc;
}


.deductionGuidanceDiv {
    justify-content: flex-start;
    text-align: left;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.defaultPercSummary {
    margin-top: -10px;
    margin-left: 20px;
    font-style: italic;
    padding: 10px;

    span {
        font-size: 15px;
        font-weight: bold;
    }

    div.label,
    label {
        margin-bottom: 0px;
        font-size: 14px;
    }
}

.btnDeductionOK {
    justify-content: left;
    display: flex;
    margin-top: 30px;
    margin-left: 25px;
}

.deductionTitle {
    display: flex;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 20px;
    text-decoration: underline;
}