.learning-container {
  //overflow-x: hidden;
  background-color: #f3f3f3;
  max-width: 96% !important;
  width: 100% !important
}

.contents {
  .curriculum-content-left {
    float: left;
    width: 300px;
    min-height: 84vh;
    // height: calc(100vh - 125px);
    overflow: auto;
    padding: 30px 20px;
    background-color: #ebf1ff;
    color: #303030;

    .divflex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }

    .titletreeview {
      font-size: 22px;
      color: #212529;
      text-decoration: none solid rgb(33, 37, 41);
      line-height: 1;
    }

    .clear-filter {
      cursor: pointer;
      text-decoration: none solid rgb(33, 37, 41);
      font-size: 14px;
      color: #0080ff;
      line-height: 1;
      text-align: right;
      padding-top: 5px;

      &:hover {
        text-decoration: underline;
      }
    }

    .actionButtoncontent {
      // margin-right: 5px;
      cursor: pointer;
      opacity: 0.5;
      margin-left: 5px;

      &.trash {
        color: #ff6666;
      }

      &.edit {
        color: #006699; //#00cc44;
        margin-top: 1px;
      }
    }
  }

  .curriculum-content-right {
    float: left;
    width: calc(100% - 300px);
    padding: 10px 20px 0px 20px;
    color: #303030;

    .headingtext {
      padding-left: 20px;
      border-bottom: 1px solid #ccc;
      margin-right: 20px;
      font-size: 18px;
      font-weight: 600;

    }
  }
}

.treeviewrecommtitle {
  font-size: 14px;
  font-weight: 500;
}

.HeaderTextContainer {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;

  h1 {
    border: #ffffff !important;
  }

  span {
    color: rgb(0, 123, 255);
    text-decoration: underline;
    cursor: pointer;
  }
}

.curriculum-content-right-mobile {
  float: left;
  padding: 10px 20px 0px 20px;
  color: #303030;
  width: 100%;
}

.userSelectEvent {
  //font-family: "Roboto", sans-serif;
  //font-family: 'Be Vietnam Pro', sans-serif;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;

}

.radiocss {
  width: 100%;
  // @media only screen and (max-width: 600px) {
  //   width:40% !important;
  // }
}

.nochallenge {
  @media only screen and (max-width: 600px) {
    font-size: 14px;
  }
}

@media only screen and (max-width: 600px) {
  .userSelectEvent {
    font-size: 14px;
  }

}

.box {
  border-bottom: 1;
  border-color: 'divider';
}

.disabledDivText {
  pointer-events: none;
  opacity: 0.2;
}

.bg-white-with-shadow111 {
  background-color: #fff;
  border: 1px solid #e0e4e8;
  border-radius: 16px;
  -webkit-box-shadow: 0px 8px 24px 0px #18181833;
  -moz-box-shadow: 0px 8px 24px 0px #18181833;
  box-shadow: 0px 8px 24px 0px #18181833;
  padding: 50px 50px !important;
  position: absolute;
  top: 300px;
  z-index: 999;
  opacity: 3;
  left: 25%;
  width: 50%;
}

@media (max-width: 1366px) {
  .learning-container {
    max-width: 96% !important;
  }
}

@media only screen and (max-width: 600px) {
  .learning-container {
    width: 100% !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    max-width: 100% !important;
  }
}

// .deni-react-treeview-container {
//   font-size: 14px !important;
//   color: #212529 !important;
//   text-decoration: none solid rgb(33, 37, 41) !important;
//   line-height: 32px !important;


// }

// .deni-react-treeview-container.classic .deni-react-treeview-item-container.classic .checkbox {
//   border-radius: 4px;
//   width: 15px;
//   height: 15px;
//   border-color: #fdbd17 !important;
// }

// .tree-view-notes .deni-react-treeview-container .deni-react-treeview-item-container .checkbox.checked {
//   background-color: #fdbd17;
//   color: #000
// }



// .curriculum-content-right-mobile {
//   float: left;
//   padding: 10px 20px 0px 20px;
//   color: #303030;
// }


.videoClass {
  width: 100%;
  position: fixed;
  display: flex;
  left: 85%;
  top: 85%;
  z-index: 10;

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {
    left: 82%;
    top: 88%;
  }
}

.videoModalDiv {
  text-align: center;
  align-items: center;
}

.videoHeader {
  margin-top: 15px;

  span {
    color: #4353B0 !important;
    font-size: 26px;
    line-height: 1.5rem;
  }
}

.videoDescription p {
  margin-top: 10px;
  font-size: 16px;
  line-height: 1.5rem;
}


.videoClosebtn {
  margin-top: 2px;
  background-color: white !important;
  color: #4353B0 !important;
  border-color: #4353B0 !important;
  font-weight: bold;
  text-transform: capitalize !important;
  font-size: 14px !important;
}

.videoDiv {
  height: 100%;
  width: 600px;
}

.btnHelpCenter {
  color: #000 !important;
  background-color: #fff !important;
}

.helpIcon {
  color: #4353B0 !important;
}

.schoolIcon {
  color: #000;
}

.awesomeIcon {
  color: gold;
}

.disabledDiv {
  pointer-events: none;
  opacity: 0.4;
}


.searchInput1 {
  float: left;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 20px;
  display: flex;
  align-items: center;
  //  padding-left: 10px;
  position: relative;
  width: 100%;
}

.Otherbtns {
  // display: flex;
  display: flex;
  position: relative;
  float: left;
  top: 80%;
}

.search-aiPlato {
  width: 130px;
  border-left: 1px solid #ccc;
  border-radius: 0 !important;
  position: absolute;
  left: calc(100% - 276px);
  transition: all 0.25s;

  &:focus {
    width: calc(100% - 200px);
    left: 45px;
    border-left: 0;
    z-index: 1;
  }
}

input {
  border: 0;
  outline: 0;
  background: #fff;
}

.labels {
  padding: 10px;
  border-radius: 30px;
  // background: transparent;
}


.boardBtns {
  width: 36px;
  height: 36px;
  background-color: #ECF1FE;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s;
  position: relative;
  cursor: pointer;
  margin: 5px 0 2px 0;
  margin-right: 5px;

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {
    margin-top: 6px;
    height: 28px;
    width: 5px;
    margin-right: 15px;
  }
}

.uploadBtnLabel {
  margin-top: -13px;
  width: 100%;
  display: flex;
  float: inline-start;
}

.uploadImgBtn {
  margin-left: -10px;
  background: white;
  height: 0px;
  margin-top: -129px;
  background: transparent;
  font-weight: 500;
  font-size: 18px;

  span {
    font-size: 16px;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {
    margin-top: -124px;
    width: 160px;
  }
}

.scanImgBtn {
  margin-left: -20px;
  background: white;
  height: 0px;
  margin-top: -129px;
}

.statementContainer {
  height: 150px;
  overflow-y: auto;
  width: 92%;
  line-height: 1;
  border-right: 1px solid lightgrey;
  padding: 8px;
  display: flex;
}

.searchInput1 {
  .ck.ck-button {
    visibility: hidden;
    pointer-events: none !important;
  }
}

.formcontrolcustom {
  display: block;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.videoAddToClass {
  position: fixed;
  bottom: 5%;
  right: 4%;

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {
    left: 75%;
    top: 83%;
  }
}


/* HTML: <div class="loader"></div> */
.threedotloader {
  width: 5px;
  height: 5px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
  margin-left: 15px;
  margin-top: 10px;
}

@keyframes l5 {
  0%  {box-shadow: 10px 0 #fff, -10px 0 #fff2;background: #fff }
  33% {box-shadow: 10px 0 #fff, -10px 0 #fff2;background: #fff2}
  66% {box-shadow: 10px 0 #fff2,-10px 0 #fff; background: #fff2}
  100%{box-shadow: 10px 0 #fff2,-10px 0 #fff; background: #fff }
}